import { NgModule } from '@angular/core';

import { CosSlideToggleComponent } from './toggle.component';

/**
 * @deprecated use `CosSlideToggleComponent` instead.
 */
@NgModule({
  imports: [CosSlideToggleComponent],
  exports: [CosSlideToggleComponent],
})
export class CosSlideToggleModule {}
