<label [attr.for]="inputId()" class="cos-slide-toggle-label" #label>
  <div
    #toggleBar
    class="cos-slide-toggle-bar self-center"
    [class.cos-slide-toggle-bar--no-side-margin]="
      !labelContent.textContent || !labelContent.textContent.trim()
    "
  >
    <input
      #input
      class="cos-slide-toggle-input cos-accessibly-hidden"
      
      type="checkbox"
      role="switch"
      [attr.id]="inputId()"
      [required]="required()"
      [attr.tabIndex]="tabIndex()"
      [checked]="checked()"
      [disabled]="disabled()"
      [attr.name]="name()"
      [attr.aria-checked]="!!checked()"
      [attr.aria-label]="ariaLabel()"
      [attr.aria-labelledby]="ariaLabelledby()"
      (change)="_onChangeEvent($event)"
      (click.silent)="_onInputClick($event)"
    />
    <div class="cos-slide-toggle-thumb-container" #thumbContainer>
      <div class="cos-slide-toggle-thumb">
        <i class="cos-slide-toggle-checkmark fa fa-check"></i>
      </div>
    </div>
  </div>

  <span
    class="cos-slide-toggle-content"
    
    #labelContent
    (cdkObserveContent)="_onLabelTextChange()"
  >
    <!-- Add an invisible span so JAWS can read the label -->
    <!--
      Please refrain from removing the `[style]` binding, as doing so
      would set the display through `el.style.display` rather than `el.setAttribute`,
      which violates CSP when `unsafe-inline` is disabled.
    -->
    <span [style.display]="'none'">&nbsp;</span>
    <ng-content />
  </span>
</label>
